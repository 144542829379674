import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  media: {
    height: 0,
    paddingTop: '100%',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartActions: {
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    fontFamily: 'montserrat',
    marginRight: '0%',
    float: 'right',
    overflow: 'auto',
  },
  itemFont: {
    fontFamily: 'montserrat',
  },
  priceFont:{
    fontFamily: 'montserrat',
    fontSize: '24px',
    marginTop: '12%'
  }
}));
