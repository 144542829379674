import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: '5%',
    fontFamily: 'montserrat',
    fontWeight: '600',
  },
  emptyButton: {
    minWidth: '150px',
    fontFamily: 'montserrat',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },
  checkoutButton: {
    minWidth: '150px',
    fontFamily: 'montserrat',
  },
  link: {
    textDecoration: 'none',
  },
  cardDetails: {
    display: 'flex',
    marginTop: '10%',
    width: '100%',
    justifyContent: 'space-between',
  },
  homeButton: {
    minWidth: '150px',
    fontFamily: 'montserrat',
    float: 'right',
    marginRight: '0px',
    overflow: 'auto',
    backgroundColor: 'goldenrod',
  },
  subTitle: {
    fontFamily: 'montserrat',
  },
  subTotal: {
    fontFamily: 'montserrat',
    float: 'right',
    overflow: 'auto'
  }
}));